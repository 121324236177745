<script setup lang="ts">
import icoMap from '@images/front-pages/icons/ico-mapping.svg?url'
import icoType from '@images/front-pages/icons/ico-select-type.svg?url'

const facilityStore = useFacilityStore()

// const { filter } = facilityStore

const typeStore = useTypeStore()
const { data: facilityTypes } = await typeStore.fetchBy({ category: 'facility' })

const filter = ref({
  itemsPerPage: 20,
  page: 1,
  sortBy: [],
  orderBy: [],
  search: '',
  name: '',
  facility_types: [],
  address: [],
  is_ng: false,
  is_ng_item: false,
  is_new: false,
  is_campaign: false,
  space_event: [],
  weekday_price: [0, 0],
  weekend_price: [0, 0],
  holiday_price: [0, 0],
  event_date: {
    start: '',
    end: '',
  },
  is_bookmark: false,
})

const navigateToPage = async (isMap: boolean) => {
  if (isMap) {
    if (!useCookie<any>('positionCenter').value)
      await getPosition()

    facilityStore.isMap = true
  }
  if (JSON.stringify(filter.value) === JSON.stringify(facilityStore.reset)) {
    facilityStore.isFilter = false
  }

  else {
    facilityStore.isFilter = true
    Object.assign(facilityStore.filter, { ...filter.value })
  }

  navigateTo({ name: 'search' })
}
</script>

<template>
  <div>
    <VContainer>
      <VRow class="match-height">
        <VCol
          cols="12"
          sm="6"
          lg="6"
        >
          <VCard>
            <VCardText>
              <VImg
                :src="icoType"
                width="80"
                height="80"
                class="mx-auto"
              />
              <h4 class="text-h4 text-center mb-4">
                施設タイプから選ぶ
              </h4>

              <div
                v-if="facilityTypes"
                class="d-flex flex-wrap gap-4 mb-8"
              >
                <div
                  v-for="item in facilityTypes"
                  :key="item.id"
                >
                  <VCheckbox
                    v-model="filter.facility_types"
                    :label="item.name"
                    :value="item.id"
                  />
                </div>
              </div>
            </VCardText>

            <VBtn
              class="card-actions"
              @click="navigateToPage(false)"
            >
              <VIcon
                start
                icon="tabler-search"
              />
              検索
            </VBtn>
          </VCard>
        </VCol>
        <VCol
          cols="12"
          sm="6"
          lg="6"
        >
          <VCard>
            <VCardText>
              <VImg
                :src="icoMap"
                width="50"
                min-height="80"
                class="mx-auto"
              />
              <h4 class="text-h4 text-center my-4">
                地図から選ぶ（マッピング検索）
              </h4>

              <VTextField
                v-model="filter.search"
                placeholder="施設名や住所をご入力ください"
                clearable
                clear-icon="tabler-x"
                class="mb-8"
              />
            </VCardText>

            <VBtn
              class="card-actions"
              @click="navigateToPage(true)"
            >
              <VIcon
                start
                icon="tabler-search"
              />
              検索
            </VBtn>
          </VCard>
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<style lang="scss" scoped>
.section-title::after {
  position: absolute;
  background: url('../../../assets/images/front-pages/icons/section-title-icon.png') no-repeat left bottom;
  background-size: contain;
  block-size: 100%;
  content: '';
  font-weight: 700;
  inline-size: 120%;
  inset-block-end: 0;
  inset-inline-start: -12%;
}
.card-actions {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}
</style>
