<script setup lang="ts">
const categoryStore = useCategoryStore()
const facilityStore = useFacilityStore()

const { data: categories } = await categoryStore.lists()

const filter = ref({
  itemsPerPage: 20,
  page: 1,
  sortBy: [],
  orderBy: [],
  search: '',
  name: '',
  facility_types: [],
  address: [],
  is_ng: false,
  is_ng_item: false,
  is_new: false,
  is_campaign: false,
  space_event: [],
  weekday_price: [0, 0],
  weekend_price: [0, 0],
  holiday_price: [0, 0],
  event_date: {
    start: '',
    end: '',
  },
  cate: [],
  is_bookmark: false,
})

const navigateToPage = (cate: number) => {
  facilityStore.isFilter = true
  facilityStore.filter = filter
  facilityStore.filter.cate = [cate]

  navigateTo({ name: 'search' })
}
</script>

<template>
  <VRow v-if="categories">
    <VCol cols="12">
      <div class="d-flex flex-wrap gap-y-4 gap-x-4 mb-4">
        <VBtn
          v-for="value in categories"
          :key="value.id"
          variant="outlined"
          color="secondary"
          @click="navigateToPage(value.id)"
        >
          {{ value.name }}
        </VBtn>
      </div>
    </VCol>
  </VRow>
</template>
