<script setup lang="ts">
import { register } from 'swiper/element/bundle'
import ItemView from '@/views/campaign/Item.vue'

register()

const useCampaign = useCampaignStore()

const { data: campaigns } = await useCampaign.fetchsDashboardClient()
</script>

<template>
  <div
    v-if="campaigns && campaigns.length"
    id="pickup"
    class="position-relative"
  >
    <div :style="{ 'background-color': 'rgb(var(--v-theme-surface))' }">
      <VContainer>
        <div class="pickups">
          <div class="headers d-flex justify-center flex-column align-center gap-y-4">
            <div class="d-flex align-center text-h3 mb-1 flex-wrap justify-center">
              <div class="position-relative me-2">
                <h3 class="section-title">
                  イチオシ
                </h3>
                <NuxtLink :to="{ name: 'facility-pickup' }">
                  <VBtn variant="plain">
                    一覧を表示
                  </VBtn>
                </NuxtLink>
              </div>
            </div>
          </div>

          <div class="swiper-centered-slide">
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ClientOnly>
              <swiper-container
                virtual="true"
                :autoplay="{
                  delay: 8000,
                  disableOnInteraction: true,
                  pauseOnMouseEnter: true,
                }"
                :slides="campaigns"
                :speed="800"
                navigation="true"
                slides-per-view="2"
                space-between="30"
                events-prefix="swiper-"
                :injectStyles="[`.swiper-button-next, .swiper-button-prev{
                  background: rgb(var(--v-theme-primary)) !important;
                  color: #fff !important;
                  padding-inline: 0.45rem !important;
                  padding-block: 0.45rem !important;
                  inline-size: 1rem !important;
                  block-size: 1rem !important;
                  border-radius: 50%
                }`]"
                :breakpoints="{

                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }"
              >
                <swiper-slide
                  v-for="(item, index) in campaigns"
                  :key="index"
                >
                  <ItemView :item="item" />
                </swiper-slide>
              </swiper-container>
            </ClientOnly>
          </div>
        </div>
      </VContainer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.pickups {
  margin-block: 2rem;
}

@media (max-width: 600px){
  .pickups{
    margin-block: 4rem;
  }
}

.section-title::after{
  position: absolute;
  background: url('../../../assets/images/front-pages/icons/section-title-icon.png') no-repeat left bottom/contain;
  background-size: contain;
  block-size: 100%;
  content: '';
  font-weight: 700;
  inline-size: 120%;
  inset-block-end: 0;
  inset-inline-start: -12%;
}
</style>
