<script setup lang="ts">
import { register } from 'swiper/element/bundle'

register()

const apiUrl = useRuntimeConfig().public.apiBaseUrl
const userData = useCookie<any>('userData')
const facilityStore = useFacilityStore()
const { data: facilities } = await facilityStore.fetchsNew(!userData.value)
</script>

<template>
  <VContainer v-if="facilities.length">
    <div class="area-section">
      <div class="headers d-flex justify-center flex-column align-center gap-y-4">
        <div class="d-flex align-center text-h3 mb-1 flex-wrap justify-center">
          <div class="position-relative me-2">
            <h3 class="section-title">
              注目施設
            </h3>
            <NuxtLink :to="{ name: 'facility-new' }">
              <VBtn variant="plain">
                一覧を表示
              </VBtn>
            </NuxtLink>
          </div>
        </div>
      </div>

      <div class="swiper-centered-slide">
        <!-- eslint-disable vue/attribute-hyphenation -->
        <ClientOnly>
          <swiper-container
            virtual="true"
            :autoplay="{
              delay: 8000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }"
            :slides="facilities"
            :speed="800"
            navigation="true"
            slides-per-view="4"
            space-between="30"
            events-prefix="swiper-"
            :injectStyles="[`.swiper-button-next, .swiper-button-prev{
                  background: rgb(var(--v-theme-primary)) !important;
                  color: #fff !important;
                  padding-inline: 0.45rem !important;
                  padding-block: 0.45rem !important;
                  inline-size: 1rem !important;
                  block-size: 1rem !important;
                  border-radius: 50%
                }`]"
            :breakpoints="{
              1024: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }"
          >
            <swiper-slide
              v-for="(item, index) in facilities"
              :key="index"
            >
              <VCard>
                <NuxtLink :to="{ name: userData ? (userData.role === 'admin' ? 'admins-facility-id' : 'clients-facility-id') : 'facility-id', params: { id: item.id } }">
                  <div v-if="item.image">
                    <VImg
                      :src="apiUrl + item.image.url_thumbnail"
                      lazy="true"
                      cover
                      height="190"
                    />
                  </div>
                  <div v-else>
                    <VImg
                      height="190"
                      class="d-flex align-center text-center"
                    >
                      <VAvatar
                        size="140"
                        variant="tonal"
                        rounded
                        color="secondary"
                        icon="tabler-photo-cancel"
                      />
                    </VImg>
                  </div>
                </NuxtLink>

                <VCardItem>
                  <VCardTitle>
                    <NuxtLink :to="{ name: userData ? (userData.role === 'admin' ? 'admins-facility-id' : 'clients-facility-id') : 'facility-id', params: { id: item.id } }">
                      <span class="text-wrap">{{ item.name }}</span>
                    </NuxtLink>
                  </VCardTitle>
                </VCardItem>

                <div
                  style="min-height:60px"
                  class="d-flex justify-center v-card-text-fix mx-2"
                >
                  <div class="me-auto">
                    <div class="d-flex ">
                      <div>
                        <VIcon
                          color="primary"
                          icon="tabler-home"
                          size="18"
                        />
                      </div>
                      <span class="mx-2">{{ item.address }}</span>
                    </div>
                  </div>
                </div>
              </VCard>
            </swiper-slide>
          </swiper-container>
        </ClientOnly>
      </div>
    </div>
  </VContainer>
</template>

<style lang="scss" scoped>
.area-section {
  margin-block: 3.25rem;
}
.section-title::after {
  position: absolute;
  background: url('../../../assets/images/front-pages/icons/section-title-icon.png') no-repeat left bottom;
  background-size: contain;
  block-size: 100%;
  content: '';
  font-weight: 700;
  inline-size: 120%;
  inset-block-end: 0;
  inset-inline-start: 0%;
}
</style>
